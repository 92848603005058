/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:16:09
 */
export default {
    label: '颜色选择器',
    type: 'color',
    property: '',
    value: ''
}
